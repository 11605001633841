import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ChartData, ChartOptions } from 'chart.js';
import { DashboardData } from '../survey/survey-new-dashboard/get-dashboard-data-model';
import { SurveyService } from '../survey/survey.service';

@Component({
  selector: 'app-external-user-dashboard',
  templateUrl: './external-user-dashboard.component.html',
  styleUrls: ['./external-user-dashboard.component.css']
})

export class ExternalUserDashboardComponent implements OnInit {

  displayedColumns = [];
  tableData: any[] = [];
  surveyUid: string;
  getPartipantExcelResponse: any;
  getDashboardResponse: any;
  data: any;
  public dashboardData = new DashboardData();
  totalData: any;
  selectedLabel: string;
  public pieChartLabels: string[] = ['Pending', 'Completed'];
  public chartColors: any[] = [
    {
      backgroundColor: ['#e0542c', '#70c4fc'],
      borderColor: ['#FFF', '#FFF'],
      borderWidth: 1
    }
  ];

  public pieChartData: any;
  public pieChartType: string = 'pie';
  allDashboard: boolean = false;
  public pieChartOptions: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.label || '';
          let value = context.raw;
          return `${label}: ${value}%`;
        }
      }
    },
    datalabels: {
      formatter: (value, ctx) => {
        return `${value}%`;
      },
      color: '#444',
      font: {
        weight: 'bold',
        size: 14
      }
    }
  }
};

  public pieChartPlugins = [pluginDataLabels];
  dashboardRequest: { surveyUId: string; formFields: { index: number; label: string; usedForFilter: boolean; options: any[]; }[]; };
  dashboardLinkId: string;

  constructor(private route: ActivatedRoute,private router: Router,private surveyService: SurveyService) {
    this.route.paramMap.subscribe(params => {
      this.dashboardLinkId = params.get('dashboardLinkId');
    });
   }

  ngOnInit() {
    this.getDashBoard(this.dashboardLinkId);
    this.displayedColumns = ['no', 'level', 'completion-rate', 'pending','completed', 'total'];
  }

  clearFilter() {
    this.selectedLabel = 'All';
    this.getDashboardData(this.dashboardRequest);
  }

  getDashBoard(dashboardLinkId) {
    this.surveyService.getDashBoardByLink(dashboardLinkId).subscribe(response => {
      this.getPartipantExcelResponse = response;
      this.surveyUid = this.getPartipantExcelResponse.surveyUId;
      this.getPartipantExcelResponse.formFields.push({
        index: 0,
        label: "All",
        usedForFilter: true,
        options: []
      });
      
      this.selectedLabel = "All";
      
      this.dashboardRequest = {
        surveyUId: this.surveyUid,
        formFields: [{
          index: 0,
          label: "All",
          usedForFilter: true,
          options: []
        }]
      };
      this.getDashboardData(this.dashboardRequest);

    }, error => {
      console.log(error);
    });
}


  onSelectionChange(event: any) {
    const selectedLabel = event.value;
    const selectedObject = this.getPartipantExcelResponse.formFields.find(field => field.label === selectedLabel);
    if (selectedObject) {
      const dashboardRequest = {
        surveyUId: this.surveyUid,
        formFields: [selectedObject]
      };
      this.getDashboardData(dashboardRequest);
    }
  }
  
  getDashboardData(obj) { 
    this.surveyService.getDashboardForSurvey(obj).subscribe(response => {
      this.getDashboardResponse = response;
      this.tableData = this.getDashboardResponse.data;
      this.pieChartData = [this.getDashboardResponse.total.pendingPercent,this.getDashboardResponse.total.completedPercent];
      if(this.getDashboardResponse.data.length===0){
        this.allDashboard = true;
      }else {
        this.allDashboard = false;
      }
    },
    error => {
      console.log(error);
    });
  }

}
